<template>
  <b-card>      
    <!-- BODY -->
    <validation-observer ref="inventoryValidation">
      <b-form
      class="p-2"
      @submit.prevent=""
      @reset.prevent
      >
        <b-row>
            <!-- Name -->
            <b-col md="6" xl="4" class="mb-1">
                <validation-provider
                    #default="validationContext"
                    name="Name"
                    rules="required"
                >
                    <b-form-group
                    label="name"
                    label-for="name"
                    >
                    <b-form-input
                        id="name"
                        v-model="name"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Enter Name"
                    />

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    <span class="text-danger text-sm" v-if="allerrors.name">{{ allerrors.name[0] }}</span>
                    </b-form-group>
                </validation-provider>
            </b-col>

            <!-- Address-->
            <b-col md="6" xl="4" class="mb-1">
                <validation-provider
                    #default="validationContext"
                    name="Address"
                    rules="required"
                >
                    <b-form-group
                    label="Address"
                    label-for="address"
                    >
                    <b-form-input
                        id="address"
                        v-model="address"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Enter Address"
                    />

                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    <span class="text-danger text-sm" v-if="allerrors.address">{{ allerrors.address[0] }}</span>
                    </b-form-group>
                </validation-provider>
            </b-col>
        </b-row>

        <b-row>
          <!-- regions -->
          <b-col md="6" xl="4" class="mb-1">
          <validation-provider
              #default="validationContext"
              name="region"
              rules="required"
          >
            <b-form-group
            label="Select Region"
            label-for="region"
            >
                <v-select
                    id="workshop"
                    v-model="region_id"
                    :state="getValidationState(validationContext)"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="regions"
                    :reduce="types => types.id"
                    select
                    class="form-control-merge"
                />
            <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
            <span class="text-danger text-sm" v-if="allerrors.region_id">{{ allerrors.region_id[0] }}</span>
            </b-form-group>
        </validation-provider>
          </b-col>  
        
          <!-- type -->
          <b-col md="6" xl="4" class="mb-1">
            <validation-provider
                #default="validationContext"
                name="type"
                rules="required"
            >
              <b-form-group
              label="Select Type"
              label-for="type"
              >
                  <v-select
                      id="workshop"
                      v-model="type_id"
                      :state="getValidationState(validationContext)"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="hospitalType"
                      :reduce="types => types.id"
                      select
                      class="form-control-merge"
                  />
              <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <span class="text-danger text-sm" v-if="allerrors.type_id">{{ allerrors.type_id[0] }}</span>
              </b-form-group>
          </validation-provider>
          </b-col>  
        </b-row>
            
                <br/>
        <b-row>
          <!-- Map -->
          <b-col md="8" xl="8" class="mb-1">
             <Map  @changeCoordinate="changeCoordinate"/>
          </b-col>
        </b-row>
               
        </b-form>

        </validation-observer>


         <!-- Form Actions -->
         <div class="d-flex mt-2">
            <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="validationForm"
            v-if="!loading"
            >
            Add
            </b-button>
            <!-- spinner -->
            <b-button
            v-if="loading"
            variant="primary"
            disabled
            class="mr-1"
            >
            <b-spinner small />
            Loading...
            </b-button>
            <!-- end spinner -->
        </div>


</b-card>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BRow, BCol, BFormInvalidFeedback, BButton, BFormFile, BCard, BCardText, BMedia, BAvatar, BSpinner, BFormTextarea, BInputGroupAppend, BInputGroup,BFormTags, BBadge
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, alphaNum, email, integer } from '@validations'
import Ripple from 'vue-ripple-directive'
import DataServices from './data/services'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Map from './map.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BCardText,
    BMedia,
    BAvatar,
    vSelect,
    BFormTextarea,
    BInputGroupAppend,
    BInputGroup,
    BSpinner,
    BRow,
    BCol,
    BCard,
    BForm,
    BFormTags,

    ValidationProvider,
    ValidationObserver,
    
    BBadge,
    flatPickr,
    ToastificationContent,
    Map,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
        required,
        alphaNum,
        email,
        integer,
        name: '',
        address: '',
        coordinate: '',
        headerType: 'Add',
        loading: false,
        region_id:'',
        type_id:'',
        allerrors: [],
    }
  },
  methods: {
    validationForm() {
      this.$refs.inventoryValidation.validate().then(success => {
        if (success) {
          this.sendData()
        }
      })
      .catch(error => {
        console.log(error)
      })
    },  
    sendData(){
      this.loading = true;
        DataServices.create(this.prepareData())
        .then(response => {
          this.loading = false
          let responseCode = response.data.responseCode
          if(responseCode == 100){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.responseMessage,
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.responseDescription
              },
            })
            this.$router.push('/hospitals')  
          }
          else if(responseCode === 101){
            console.log('error');
            this.allerrors = response.data.errors
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
          else if(responseCode == 102){
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.responseDescription,
                variant: 'danger'
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
                  
          if (error.response.data.responseCode == 102) {
            this.allerrors = error.response.data.error
          }
          let message = Error
          if (error.response !== undefined) {
            message = error.response.data.message;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'BellIcon',
              text: message,
              variant: 'danger'
            },
          })
        });
    },
    initValues(){
        this.name = null
        this.region_id= null
        this.address = null
        this.coordinate= null
    },
    prepareData() {
      return {
        name: this.name,
        region_id: this.region_id,
        type_id: this.type_id,
        address: this.address,
        coordinate: this.coordinate
      }
    },
    changeCoordinate(val){ 
      this.coordinate = val
    },
  },
  computed: {
    regions (){
      return this.$store.getters['admin/GetRegions'] 
    },
    hospitalType (){
      return this.$store.getters['admin/GetHospitalType'] 
    }
  },
  mounted() {

  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      avatarText
    }
  },
}
</script>



<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';


#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

</style>
